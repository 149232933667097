globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"99198a08a56f5fda55a99fe77af2276f76f35698"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  normalizeDepth: 5,
  tracesSampleRate: 0,
  environment: process.env.ENV_NAME,
  dsn: 'https://a2a298171118439bbee5feecd28aca55@o1136612.ingest.sentry.io/4504774924959744',
});
